import { PrioritiesOptions } from '../../static-data/PrioritiesOptions';

export const filtersCategory = [
  {
    name: 'name;id',
    label: 'Pesquisar por código ou nome',
    xl: 5,
    lg: 4,
    placeHolder: 'Pesquise por código ou nome da categoria...',
    type: 'search',
    filled: true,
  },
  {
    name: 'integrationPlatform',
    label: 'Plataforma',
    labelSelect: 'label',
    placeHolder: 'Selecione',
    type: 'select',
    filled: false,
    options: ['OCC', 'ANYMARKET'],
  },
];

export const filtersCategorySeller = [
  {
    name: 'name;id',
    label: 'Pesquisar por código ou nome',
    xl: 5,
    lg: 4,
    placeHolder: 'Pesquise por código ou nome da categoria...',
    type: 'search',
    filled: true,
  },
];

export default filtersCategory;
