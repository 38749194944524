<template>
  <v-container class="crud-content" fill-height>
    <ListLayout :title="title" :info="info" :buttonActions="buttonActions">
      <template v-slot:filter>
        <DataFilter @filter="changeFilter" :search="search" :filters="filters" />
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="options"
          :server-items-length="totalElements"
          multi-sort
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
          <template v-slot:[`item.integrationPlatform`]="{ item }">
            <div :class="item.integrationPlatform.length > 3 ? 'camelcase' : 'uppercase'">{{ item.integrationPlatform }}</div>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <div>{{ item.priorityText }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-center">
              <v-icon color="primary" @click="openDetail(item.id, item.integrationPlatform)">mdi-pencil</v-icon>
              <v-icon color="primary" @click="openDelete(item.id, item.integrationPlatform)">mdi-delete</v-icon>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
            Nenhum resultado encontrado
          </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
    <AlertModal :showModal="showModal">
      <template v-slot:header>
        <div class="d-flex flex-column">
          Atenção!
        </div>
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <div class="mb-3">Todas as informações deste categoria serão perdidas com esta ação.</div>
          <div>Deseja mesmo excluir a categoria?</div>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn outlined color="primary" class="mr-3" @click="closeModal()">CANCELAR EXCLUSÃO</v-btn>
        <v-btn color="primary" @click="deleteCategory()">SIM, QUERO EXCLUIR</v-btn>
      </template>
    </AlertModal>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import DataFilter from '@/components/template/list/DataFilter';
import notification from '@/service/notification';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import isAdmin from '@/utils/user-utils';
import { filtersCategory, filtersCategorySeller } from './Filters';
import { PrioritiesOptions } from '../../static-data/PrioritiesOptions';
import { listCategoryPage, deleteCategory } from '@/service/category/category-service';
import { routerPush } from '@/service/kplaceRouter';
import AlertModal from '@/components/modal/AlertModal';

export default {
  components: {
    ListLayout,
    DataFilter,
    AlertModal,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      title: 'Categorias',
      info: '',
      search: '',
      options: {},
      showModal: false,
      categoryId: null,
      integrationPlatform: null,
      sortable: {
        name: 'ASC',
      },
      buttonActions: isAdmin() ? [{ name: 'CADASTRAR CATEGORIA', action: this.newCategory }] : [],
      priorities: PrioritiesOptions,
      isAdmin: isAdmin(),
      loading: true,
      filters: isAdmin() ? filtersCategory : filtersCategorySeller,
      header: [
        {
          text: 'Código', align: 'start', sortable: true, value: 'id',
        },
        {
          text: 'Nome', sortable: true, value: 'name',
        },
        {
          text: 'Descrição', sortable: true, value: 'description',
        },
        {
          text: 'Plataforma', sortable: true, value: 'integrationPlatform',
        },
        {
          text: 'Prioridade', sortable: true, value: 'priority',
        },
      ],
      content: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.init();
      },
    },
  },
  async mounted() {
    await this.init();
    if (isAdmin()) this.getAddActions();
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        const {
          sortBy, sortDesc, page, itemsPerPage,
        } = this.options;
        const data = await listCategoryPage(page, itemsPerPage, this.query, sortBy, sortDesc);
        if (data.status !== 200) {
          notification(`${data?.response?.data?.message}`, 'error');
        } else {
          this.content = data.data.register.map((item) => ({
            ...item,
            priorityText: this.getPriority(item.priority),
          }));
          this.totalElements = data.data.totalRegister;
        }
      } catch (e) {
        notification(`Erro ao realizar busca das categorias: ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    getAddActions() {
      this.header.push({ text: 'Ação', sortable: false, value: 'actions' });
    },
    getPriority(priority) {
      return priority ? this.priorities.find((keyValue) => keyValue.value === priority).text : 'Prioridade não cadastrada';
    },
    openDetail(id, integrationPlatform) {
      routerPush(`/category/forms/edit/${id}/${integrationPlatform}`);
    },
    newCategory() {
      routerPush('/category/forms');
    },
    openDelete(id, integrationPlatform) {
      this.categoryId = id;
      this.integrationPlatform = integrationPlatform;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.categoryId = null;
      this.integrationPlatform = null;
    },
    async deleteCategory() {
      try {
        this.loading = true;
        const data = await deleteCategory(this.categoryId, this.integrationPlatform);
        if (data.status !== 204) {
          notification(`${data?.response?.data?.message}`, 'error');
        } else {
          notification('Categoria excluída com sucesso!', 'success');
          this.closeModal();
          this.init();
        }
      } catch (e) {
        notification(`Erro ao excluir categoria: ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}
</style>
