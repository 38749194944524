var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"crud-content",attrs:{"fill-height":""}},[_c('ListLayout',{attrs:{"title":_vm.title,"info":_vm.info,"buttonActions":_vm.buttonActions},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('DataFilter',{attrs:{"search":_vm.search,"filters":_vm.filters},on:{"filter":_vm.changeFilter}})]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"headers":_vm.header,"loading":_vm.loading,"items":_vm.content,"options":_vm.options,"server-items-length":_vm.totalElements,"multi-sort":"","footer-props":{
          'items-length': 100,
          'items-per-page-text': 'Linhas por página',
          'items-per-page-options': [10, 20, 50],
          pageText: '{0}-{1} de {2}',
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.integrationPlatform",fn:function(ref){
        var item = ref.item;
return [_c('div',{class:item.integrationPlatform.length > 3 ? 'camelcase' : 'uppercase'},[_vm._v(_vm._s(item.integrationPlatform))])]}},{key:"item.priority",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.priorityText))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDetail(item.id, item.integrationPlatform)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDelete(item.id, item.integrationPlatform)}}},[_vm._v("mdi-delete")])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true)})]},proxy:true}])}),_c('AlertModal',{attrs:{"showModal":_vm.showModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" Atenção! ")])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"mb-3"},[_vm._v("Todas as informações deste categoria serão perdidas com esta ação.")]),_c('div',[_vm._v("Deseja mesmo excluir a categoria?")])])]},proxy:true},{key:"footer",fn:function(){return [_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("CANCELAR EXCLUSÃO")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteCategory()}}},[_vm._v("SIM, QUERO EXCLUIR")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }